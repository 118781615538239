
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import { ServiceStatus } from "@/utils/status";
import { RoleCodes } from "@/utils/roles";

@Component({
  components: {
    Btn,
    Card,
    ServicesSelector,
    SelectedServices,
    UsersSelector,
    SelectedUsers,
    Modal,
  },
  computed: {},
})
export default class AssignServicesToAdmins extends Vue {
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.Service[];
  @Getter getRole;
  @Action updateUsersServices;
  @Mutation resetSelectedServices;
  @Mutation resetSelectedUsers;
  @Mutation showError;
  @Mutation showSuccess;

  showConfirmationModal = null;

  getRolesFilter() {
    return [this.getRole(RoleCodes.ADMIN2).guid];
  }

  showConfModal() {
    if (_.isEmpty(this.selectedServices) || _.isEmpty(this.selectedUsers)) {
      this.showError("Selezionare almeno un servizio e un amministratore");
    } else {
      this.showConfirmationModal = true;
    }
  }

  async save() {
    const updated = await this.updateUsersServices({
      users: _.map(this.selectedUsers, "guid"),
      services: _.map(this.selectedServices, "guid"),
      status: ServiceStatus.ASSIGNED,
    });
    this.showConfirmationModal = null;
    if (updated) {
      this.resetSelectedServices();
      this.resetSelectedUsers();
      this.showSuccess("Servizi correttamente associati");
    } else {
      this.showError("Errore durante l'associazione dei servizi");
    }
  }
}
