
import * as Api from "@/types/graphql";
import { StatusCodes } from "@/utils/status";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {},
  watch: {
    selectedUsersState(val, prev) {
      if (!this.useSelected2) {
        this.selectedUsers = val;
      }
    },
    selectedUsersState2(val, prev) {
      if (this.useSelected2) {
        this.selectedUsers = val;
      }
    },
    hiddenUsers(val, prev) {
      this.initItems();
    },
  },
})
export default class UsersSelector extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;

  usersState: Api.User[];
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsersState: Api.User[];
  @State((state: StateStore) => state.data.selectedUsers2)
  selectedUsersState2: Api.User[];
  @State((state: StateStore) => state.data.companyEmployees)
  companyEmployees: Api.User[];
  @Action getUsers;
  @Mutation addSelectedUsers;
  @Mutation removeSelectedUser;
  @Mutation setSelectedUsers;
  @Mutation setSelectedUsers2;

  @Prop({ default: "Seleziona utente" })
  label: string;
  @Prop({ default: true })
  multiple: boolean;
  @Prop({ default: false })
  disabled: boolean;
  @Prop({ default: false })
  useSelected2: boolean;
  @Prop({ default: false })
  showSelected: boolean;
  @Prop()
  rolesFilter: string[];
  @Prop({
    default: () => {
      return [];
    },
  })
  statusFilter: StatusCodes[];
  @Prop({ default: false })
  onlyTeachers: boolean;
  @Prop()
  hiddenUsers: Api.User[];
  @Prop({ default: false })
  forceSelectInit: boolean;
  @Prop({ default: false })
  showCurrentCompanyEmployees: boolean;

  options = [];
  selectedUsers = [];
  selected = [];

  async mounted() {
    this.usersState = [];
    if (this.showCurrentCompanyEmployees) {
      this.usersState = this.companyEmployees;
    } else {
      this.usersState = await this.getUsers({
        roleFilter: this.rolesFilter,
        statusFilter: this.statusFilter,
        isTeacher: this.onlyTeachers ? true : null,
        return: true,
      });
    }
    this.usersState = _.sortBy(this.usersState, "name");
    if (this.forceSelectInit) {
      this.selectedUsers = this.getSelected();
    }
    this.initItems();
  }

  getSelected() {
    if (this.useSelected2) {
      return this.selectedUsersState2;
    } else {
      this.selectedUsersState;
    }
  }

  setSelected(users) {
    if (this.useSelected2) {
      return this.setSelectedUsers2(users);
    } else {
      this.setSelectedUsers(users);
    }
  }

  getMenuProps() {
    return {
      closeOnContentClick: !this.multiple,
    };
  }

  capitalize(text) {
    return _.capitalize(text);
  }

  initItems() {
    if (_.isUndefined(this.hiddenUsers)) {
      this.initOptions(this.usersState);
    } else {
      const hiddenGuids = _.map(this.hiddenUsers, "guid");
      this.initOptions(
        _.filter(this.usersState, (user) => {
          return !hiddenGuids.includes(user.guid);
        })
      );
    }
  }

  async initOptions(users: Api.User[]) {
    this.options = users;
  }

  filter(item, queryText) {
    if ((queryText as string)?.length < 3) return false;

    return (
      item.name
        .toString()
        .toLowerCase()
        .includes(queryText.toString().toLowerCase()) ||
      item.surname
        .toString()
        .toLowerCase()
        .includes(queryText.toString().toLowerCase())
    );
  }

  isSelected(user) {
    return !_.isNil(
      _.find(this.getSelected(), (u) => {
        return u.guid == user.guid;
      })
    );
  }

  onChange(users) {
    if (!this.multiple) {
      if (_.isEmpty(users)) {
        this.setSelected([]);
      } else if (_.last(users) instanceof Object) {
        this.setSelected([_.last(users)]);
      }
      this.$emit("selected", _.last(users));
    } else {
      const selected = _.filter(users, (user) => {
        return user instanceof Object;
      });
      this.setSelected(selected);
      this.$emit("selected", selected);
    }
  }
}
