
import AssignServicesToAdmins from "@/components/AssignServicesToAdmins.vue";
import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    AssignServicesToAdmins,
  },
})
export default class AssignServicesToAdminsView extends Vue {
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedServices;

  mounted(){
    this.resetSelectedUsers();
    this.resetSelectedServices();
  }
}
