
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";

@Component({
  components: {},
  watch: {
    selectedUsers(val) {
      if (_.isUndefined(this.customList)) {
        this.initUsersList(val);
      }
    },
    customList(val) {
      this.items = val;
    },
  },
})
export default class SelectedUsers extends Vue {
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.User[];
  @Mutation removeSelectedUser;
  @Mutation resetSelectedUsers;

  @Prop()
  customList: Api.User[];
  @Prop({ default: true })
  enableDelete: boolean;
  @Prop({default: false})
  withPadLeft: boolean;

  items = [];

  initUsersList(users: Api.User[]) {
    this.items = users;
  }

  mounted() {
    if (_.isUndefined(this.customList)) {
      this.initUsersList(this.selectedUsers);
    } else {
      this.initUsersList(this.customList);
    }
  }

  beforeDestroy() {
    this.resetSelectedUsers();
  }
}
